export const posts = [
    {
        id: '1',
        title: 'The Ultimate Guide to Tree Pruning in Johnson City',
        metaTitle: 'Your Comprehensive Guide to Tree Pruning in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Are you looking to understand tree pruning and its importance better? This article shares the ultimate guide for Johnson City homeowners.',
        slug: 'ultimate-guide-to-tree-pruning-in-johnson-city',
        tags: ['tree pruning', 'guide', 'Johnson City'],
        metaDescription: 'Curious about tree pruning in Johnson City? Discover our professional guide to keep your trees in optimal health and enhance their aesthetic appeal.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'The Essential Steps to Safe Tree Removal in Johnson City',
        metaTitle: 'How to Safely Remove a Tree in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Removing a tree can sometimes be a necessity. This article provides essential steps to safe and efficient tree removal for Johnson City homeowners.',
        slug: 'essential-steps-to-safe-tree-removal-in-johnson-city',
        tags: ['tree removal', 'safety', 'Johnson City'],
        metaDescription: 'Learn the vital steps to safe and efficient tree removal in Johnson City with our professional guide.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '3',
        title: 'Benefits of Professional Tree Trimming in Johnson City',
        metaTitle: 'Why You Should Consider Professional Tree Trimming in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Considering tree trimming for your property? Discover the benefits of professional tree trimming services in Johnson City with our latest blog post.',
        slug: 'benefits-of-professional-tree-trimming-in-johnson-city',
        tags: ['tree trimming', 'benefits', 'Johnson City'],
        metaDescription: 'Understand the significant benefits of professional tree trimming in Johnson City with our insightful blog post.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '4',
        title: 'Understanding the Process of Stump Grinding in Johnson City',
        metaTitle: 'Your Guide to Stump Grinding in Johnson City | Johnson City Tree Service Blog',
        excerpt: 'Have an unsightly stump in your yard? Learn about the process of stump grinding in Johnson City, TN, and why it can be crucial for your property.',
        slug: 'understanding-stump-grinding-in-johnson-city',
        tags: ['stump grinding', 'process', 'Johnson City'],
        metaDescription: 'Discover the process and benefits of stump grinding in Johnson City with our comprehensive blog post.',
        lastModified: new Date('2023-12-03').toISOString()
    },
]
