import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>Regular tree health consulting is crucial for the health and beauty of your trees, especially in an urban
                setting like New Rochelle. Consistent care not only ensures their aesthetic appeal but also their
                long-term vitality.</Text>
            <Heading as="h3" fontSize={'xl'}>Advantages of Routine Tree Care:</Heading>
            <List styleType="disc">
                <ListItem>Health Monitoring: Regular maintenance helps in early detection of diseases or pest
                    infestations, ensuring timely interventions.</ListItem>
                <ListItem>Structural Integrity: Routine pruning and trimming keep trees structurally sound and
                    safe.</ListItem>
                <ListItem>Longevity: Consistent care extends the life of your trees, allowing them to thrive for
                    decades.</ListItem>
                <ListItem>Landscape Aesthetics: Well-maintained trees enhance the overall beauty of your landscape,
                    increasing property value.</ListItem>
            </List>
            <Text>For comprehensive tree health consulting services, explore our <Link href='/tree-health-consulting'>tree
                maintenance</Link> page. New Rochelle Tree Service ensures your trees are always at their best.</Text>
        </Stack>
    )
}