import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost4 = () => {
    return (
        <Stack spacing={3}>
            <Text>Urban tree care in New Rochelle presents unique challenges. From limited growing spaces to pollution,
                urban trees need specialized care to thrive in these conditions.</Text>
            <Heading as="h3" fontSize={'xl'}>Navigating Urban Tree Care Challenges:</Heading>
            <List styleType="disc">
                <ListItem>Space Limitations: Understand how to maximize the health of trees in confined urban
                    spaces.</ListItem>
                <ListItem>Pollution Impact: Learn strategies to mitigate the effects of urban pollution on tree
                    health.</ListItem>
                <ListItem>Human Interference: Explore ways to protect trees from damage caused by human activities and
                    urban development.</ListItem>
                <ListItem>Resource Competition: Find out how to ensure your urban trees get the necessary nutrients and
                    water in a competitive environment.</ListItem>
            </List>
            <Text>For expert guidance on urban tree care, visit our <Link href='/tree-health-consulting'>tree health
                consulting</Link> page. New Rochelle Tree Service is equipped to tackle these urban challenges
                effectively.</Text>
        </Stack>
    )
}