import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost2 = () => {
    return (
        <Stack spacing={3}>
            <Text>As winter approaches in New Rochelle, it's essential to prepare your trees for the cold months ahead.
                Proper preparation can help your trees withstand the harsh weather and thrive in the spring.</Text>
            <Heading as="h3" fontSize={'xl'}>Winter Tree Care Tips:</Heading>
            <List styleType="disc">
                <ListItem>Inspect and Prune: Late fall is a great time to inspect your trees for any dead or dying
                    branches that should be pruned before winter.</ListItem>
                <ListItem>Mulching: Apply a layer of mulch around the base of your trees to provide insulation and
                    retain moisture during the dry winter months.</ListItem>
                <ListItem>Watering: Ensure your trees are well-watered in the fall so they enter winter with adequate
                    moisture reserves.</ListItem>
                <ListItem>Protection: Consider wrapping young trees or those with thin bark to protect them from extreme
                    cold and wind.</ListItem>
            </List>
            <Text>For more winter tree care advice or services, visit our <Link href='/tree-health-consulting'>tree
                health consulting</Link> page. New Rochelle Tree Service is here to help your trees survive and
                flourish, even in winter.</Text>
        </Stack>
    )
}